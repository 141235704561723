import * as React from "react";
import { Helmet } from "react-helmet";
import SEO from "../components/seo/SEO";
import Layout from "../components/layout/Layout";

export default function Privacy() {
  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>Privacy policy - /meet 4 Slack</title>
        <meta
          name="description"
          content="/meet for Slack app was built with privacy in mind and will only request permissions it needs to function. It will never ask for any unnecessary user or workspace data."
        />
      </Helmet>
      <PrivacyContent />
    </Layout>
  );
}

function PrivacyContent() {
  return (
    <>
      <div className="relative pt-32 py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true"></div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Privacy policy
              </span>
            </h1>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>
              Your privacy is important to us. It is our policy to respect your privacy and comply
              with any applicable law and regulation regarding any personal information we may
              collect about you, including across our website, https://meetslack.com, and other
              sites we own and operate (such as htttps://projectundefined.com).
              <p>
                This policy was last updated on 15 June 2022.
              </p>
            </p>
            <h3>Information we collect</h3>
            <p>
              Information we collect includes both information you knowingly and actively provide us
              when using or participating in any of our services and promotions, and any information
              automatically sent by your devices in the course of accessing our products and
              services.
            </p>
            <h3>Log data</h3>
            <p>
              When you visit our website, our servers may automatically log the standard data
              provided by your web browser. It may include your device's Internet Protocol (IP)
              address, your browser type and version, the pages you visit, the time and date of your
              visit, the time spent on each page, other details about your visit, and technical
              details that occur in conjunction with any errors you may encounter.
            </p>
            <p>
              Please be aware that while this information may not be personally identifying by
              itself, it may be possible to combine it with other data to personally identify
              individual persons.
            </p>
            <h3>Personal information</h3>
            <p>We may ask for personal information which may include Name and Email address.</p>
            <h3>Legitimate reasons for processing your personal information</h3>
            <p>
              We only collect and use your personal information when we have a legitimate reason for
              doing so. In which instance, we only collect personal information that is reasonably
              necessary to provide our services to you.
            </p>
            <h3>Collection and use of information</h3>
            <p>
              We may collect personal information from you when you do any of the following on our
              website:
            </p>
            <ul>
              <li>Use a mobile device or web browser to access our content</li>
              <li>Grant your consent using Google OAuth authorization</li>
              <li>Grant your consent using Slack OAuth authorization</li>
              <li>Contact us via email, social media, or on any similar technologies</li>
              <li>When you mention us on social media</li>
            </ul>
            <p>
              We may collect, hold, use, and disclose information for the following purposes, and
              personal information will not be further processed in a manner that is incompatible
              with these purposes:
            </p>
            <p>
              Functionality of the Meet 4 Slack application, in particular: Consent to access and
              manage your Google Calendar is required for us to create and delete meetings in your
              calendar and return to you the appropriate Google Meet link. We access your Google
              user data using API calls authenticated by your API token which is given to us when
              you authorize the application on the OAuth consent screen. We only store the necessary
              data to maintain the functionality of the application. We do not share any of your
              Google user data.
            </p>
            <h3>Security of your personal information</h3>
            <p>
              When we collect and process personal information, and while we retain this
              information, we will protect it within commercially acceptable means to prevent loss
              and theft, as well as unauthorized access, disclosure, copying, use, or modification.
            </p>
            <p>
              Although we will do our best to protect the personal information you provide to us, we
              advise that no method of electronic transmission or storage is 100% secure, and no one
              can guarantee absolute data security. We will comply with laws applicable to us in
              respect of any data breach.
            </p>
            <p>
              You are responsible for selecting any password and its overall security strength,
              ensuring the security of your own information within the bounds of our services.
            </p>
            <h3>How long we keep your personal information</h3>
            <p>
              We keep your personal information only for as long as we need to. This time period may
              depend on what we are using your information for, in accordance with this privacy
              policy. If your personal information is no longer required, we will delete it or make
              it anonymous by removing all details that identify you.
            </p>
            <p>
              However, if necessary, we may retain your personal information for our compliance with
              a legal, accounting, or reporting obligation or for archiving purposes in the public
              interest, scientific, or historical research purposes or statistical purposes.
            </p>
            <h3>Payments</h3>
            <p>
              We may provide paid products and/or services. In that case, we use third-party
              services for payment processing (e.g. payment processors).
            </p>
            <p>
              We will not store or collect your payment card details. That information is provided
              directly to our third-party payment processors whose use of your personal information
              is governed by their Privacy Policy. These payment processors adhere to the standards
              set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint
              effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
              requirements help ensure the secure handling of payment information.
            </p>
            <p>
              We use Stripe for all payment processing. Their privacy policy is avaialble on
              https://stripe.com/privacy.
            </p>
            <h3>Children's privacy</h3>
            <p>
              We do not aim any of our products or services directly at children under the age of
              13, and we do not knowingly collect personal information about children under 13.
            </p>
            <h3>International transfers of personal information</h3>
            <p>
              The personal information we collect is stored and/or processed where we or our
              partners, affiliates, and third-party providers maintain facilities. Please be aware
              that the locations to which we store, process, or transfer your personal information
              may not have the same data protection laws as the country in which you initially
              provided the information. If we transfer your personal information to third parties in
              other countries: (i) we will perform those transfers in accordance with the
              requirements of applicable law; and (ii) we will protect the transferred personal
              information in accordance with this privacy policy.
            </p>
            <h3>Your rights and controlling your personal information</h3>
            <p>
              You always retain the right to withhold personal information from us, with the
              understanding that your experience of our website may be affected. We will not
              discriminate against you for exercising any of your rights over your personal
              information. If you do provide us with personal information you understand that we
              will collect, hold, use and disclose it in accordance with this privacy policy. You
              retain the right to request details of any personal information we hold about you. To
              request deletion of your information, please contact us on help@meetslack.com.
            </p>
            <p>
              If we receive personal information about you from a third party, we will protect it as
              set out in this privacy policy. If you are a third party providing personal
              information about somebody else, you represent and warrant that you have such person's
              consent to provide the personal information to us.
            </p>
            <p>
              If you have previously agreed to us using your personal information for direct
              marketing purposes, you may change your mind at any time. We will provide you with the
              ability to unsubscribe from our email-database or opt out of communications. Please be
              aware we may need to request specific information from you to help us confirm your
              identity.
            </p>
            <p>
              If you believe that any information we hold about you is inaccurate, out of date,
              incomplete, irrelevant, or misleading, please contact us using the details provided in
              this privacy policy. We will take reasonable steps to correct any information found to
              be inaccurate, incomplete, misleading, or out of date.
            </p>
            <p>
              If you believe that we have breached a relevant data protection law and wish to make a
              complaint, please contact us using the details below and provide us with full details
              of the alleged breach. We will promptly investigate your complaint and respond to you,
              in writing, setting out the outcome of our investigation and the steps we will take to
              deal with your complaint. You also have the right to contact a regulatory body or data
              protection authority in relation to your complaint.
            </p>
            <h3>Limits of our policy</h3>
            <p>
              Our website may link to external sites that are not operated by us. Please be aware
              that we have no control over the content and policies of those sites, and cannot
              accept responsibility or liability for their respective privacy practices.
            </p>
            <h3>Changes to this policy</h3>
            <p>
              At our discretion, we may change our privacy policy to reflect updates to our business
              processes, current acceptable practices, or legislative or regulatory changes. If we
              decide to change this privacy policy, we will post the changes here at the same link
              by which you are accessing this privacy policy.
            </p>
            <p>
              If required by law, we will get your permission or give you the opportunity to opt in
              to or opt out of, as applicable, any new uses of your personal information.
            </p>
            <h3>Contact us</h3>
            <p>
              For any questions or concerns regarding your privacy, including requests to delete
              your information, you may contact us on help@meetslack.com.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
